import clsx from 'clsx';

const variants = {
	display1: 'h1',
	display2: 'h1',
	display3: 'h1',
	display4: 'h1',
	main: 'h1',
	title1: 'h1',
	title2: 'h2',
	title3: 'h3',
	title4: 'h4',
	title5: 'h5',
	bodyBig: 'p',
	body: 'p',
	bodySmall: 'p',
	link: 'p',
} as const;

const typographyVariants = {
	display1: 'text-2xl xl:text-3xl font-bold',
	display2: 'text-xl md:text-2xl font-bold',
	display3: 'text-2xl font-bold',
	display4: 'text-xl font-bold',
	main: 'text-xl text-secondaryTextDark',
	title1: 'text-xl font-bold',
	title2: 'text-lg',
	title3: 'text-lg',
	title4: 'text-md font-semibold',
	title5: 'text-md font-bold md:text-[32px]',
	bodyBig: 'text-md',
	body: 'text-base',
	bodySmall: 'text-sm',
	link: 'text-base text-primaryBrand cursor-pointer',
	linkSmall: 'text-sm text-primaryBrand cursor-pointer',
};

export type TypographyProps = {
	variant?: keyof typeof variants;
	className?: string;
	dataTestId?: string;
	children: React.ReactNode | string;
};

export const Typography = ({
	variant = 'body',
	className,
	children,
	dataTestId,
}: TypographyProps) => {
	const Component = variants[variant];
	const typographyStyles = typographyVariants[variant];

	return (
		<Component
			className={clsx('font-sans', typographyStyles, className)}
			data-test-id={dataTestId}
		>
			{children}
		</Component>
	);
};
