import { Spinner } from '../Spinner';
import clsx from 'clsx';
import { ButtonHTMLAttributes, forwardRef } from 'react';

const variants = {
	primary:
		'bg-primaryBrand text-primaryTextLight hover:bg-primaryBrandHover active:bg-primaryBrandActive disabled:bg-disabledBackground disabled:text-disabledText focus:outline-none focus:ring focus:ring-primaryBrand',
	secondary:
		'bg-primaryBackground text-primaryTextDark border border-primaryBorder hover:border-primaryBorderHover active:border-primaryBorderActive disabled:border-disabledBorder disabled:text-disabledText focus:outline-none focus:ring focus:ring-primaryBrand',
	blank: 'bg-primaryBackground text-primaryTextDark disabled:text-disabledText focus:outline-none focus:ring focus:ring-primaryBrand',
	icon: 'bg-primaryBackground text-primaryTextDark disabled:text-disabledText focus:outline-none focus:ring focus:ring-primaryBrand',
	transparent:
		'bg-transparent text-primaryTextDark disabled:text-disabledText focus:outline-none focus:ring focus:ring-primaryBrand enabled:hover:underline',
};

const sizes = {
	xs: 'px-2.5 py-1.5 text-xs',
	sm: 'px-3 py-2 text-sm',
	md: 'px-4 py-2 text-base',
	lg: 'px-4 py-3 text-base',
	xlg: 'px-6 py-3 text-md',
	icon: 'px-2 py-2 text-base',
};

type IconProps =
	| { startIcon: React.ReactElement; endIcon?: never }
	| { endIcon: React.ReactElement; startIcon?: never }
	| { endIcon?: undefined; startIcon?: undefined };

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	variant?: keyof typeof variants;
	size?: keyof typeof sizes;
	isLoading?: boolean;
	disabled?: boolean;
	dataTestId?: string;
} & IconProps;

export type ButtonClassProps = {
	variant?: keyof typeof variants;
	size?: keyof typeof sizes;
	className?: string;
	disabled?: boolean;
};

export const getButtonClass = (options: ButtonClassProps) => {
	const { size = 'md', variant = 'primary', className } = options;
	return clsx(
		'flex flex-row justify-center items-center rounded',
		sizes[size],
		variants[variant],
		className
	);
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			type = 'button',
			className = '',
			variant = 'primary',
			size = 'md',
			name = '',
			isLoading = false,
			disabled = false,
			startIcon,
			dataTestId,
			endIcon,
			...props
		},
		ref
	) => {
		return (
			<button
				ref={ref}
				type={type}
				className={getButtonClass({ size, variant, className })}
				name={name}
				data-test-id={dataTestId}
				disabled={disabled}
				{...props}
			>
				{isLoading && <Spinner size='sm' className='text-current' />}
				{!isLoading && startIcon}
				<span
					className={clsx(
						variants['icon'] ? '' : 'w-full mt-[1px]',
						isLoading ? 'ml-2' : ''
					)}
				>
					{props.children}
				</span>
				{!isLoading && endIcon}
			</button>
		);
	}
);

Button.displayName = 'Button';
