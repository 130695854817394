import { Close } from 'mdi-material-ui';

const variants = {
	withIntersection: ': ',
	noIntersection: '',
};

export type ChipProps = {
	onClose?: () => void;
	name?: string;
	value?: string;
	variant?: keyof typeof variants;
	style?: 'color';
};

export const Chip = (props: ChipProps) => {
	const { onClose, name, value, variant = 'withIntersection', style } = props;
	return (
		<div
			className={`${
				style === 'color' ? 'bg-taggedSearchCriteria' : 'bg-highlight'
			} p-2 rounded-md text-base text-primaryTextDark`}
			data-test-id='chip'
		>
			{name && (
				<span>
					{name}
					{variants[variant]}
				</span>
			)}
			<span className={`${name ? 'font-semibold' : ''}`}>{value}</span>
			{onClose && (
				<span>
					<Close
						fontSize='small'
						className='ml-4 float-right cursor-pointer'
						onClick={onClose}
					></Close>
				</span>
			)}
		</div>
	);
};
