export const API_METHODS = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	PATCH: 'PATCH',
	DELETE: 'DELETE',
	OPTIONS: 'OPTIONS',
};

const CONSENT_DELEGATED_STATUS_CODES = {
	status: '530',
};
const SCREENING_RESCREENING_STATUS_CODES = {
	status: '580',
	cancelled: '0',
};
const SCREENING_FAILED_STATUS_CODES = {
	cancelled: '6800',
};
const SCREENING_REQUIRED_STATUS_CODES = {
	status: '550',
	cancelled: '0',
};
const SCREENING_PASSED_STATUS_CODES = {
	status: '600',
};
export const PATIENT_SCREENING_STATUS_CODE = {
	CONSENT_DELEGATED_STATUS_CODES,
	SCREENING_FAILED_STATUS_CODES,
	SCREENING_REQUIRED_STATUS_CODES,
	SCREENING_PASSED_STATUS_CODES,
	SCREENING_RESCREENING_STATUS_CODES,
};
export enum Status {
	Candidate = 100,
	Approved = 500,
	ReadyToEngage = 510,
	Engaged = 520,
	ConsentDelegated = 530,
	Consented = 550,
	Dispatched = 560,
	ReScreening = 580,
	Screened = 600,
	Contacted = 602,
	Scheduled = 604,
	Screening = 606,
	Randomised = 610,
}

export enum CancelledStatus {
	NotCancelled = 0,
	Denied = 3000,
	NotEligible = 6500,
	ScreeningNotEligible = 6800,
	RandomisingExcluded = 6900,
	OptedOut = 2000,
}

export const cookieConsentFeatureFlag = true;

export const supportEmail = 'research@umed.io';
export const patientSupportEmailUk = 'patientsupport@umed.io';
export const patientSupportEmailUs = 'patientservices@accesspd.org';
export const patientSupportPhoneNumberUk = '+442034818905';
export const patientSupportPhoneNumberUs = '1 888 454 5580';

const isProdSite = import.meta.env.VITE_SITE_ENV === 'prod';

export const defaultSiteLanguage = import.meta.env.VITE_SITE_LOCALE === 'us' ? 'en-us' : 'en-gb';
export const isUs = defaultSiteLanguage === 'en-us';
export const phoneNumberPrefix = isUs ? '+1' : '+44';

export const RUDDERSTACK_SETUP = {
	WRITE_KEY: isProdSite ? '2YFoApdBMScrKTmD47sirBGMxtU' : '2Vvm8tADMCJaJXKRdhslYbodktd',
	DATA_PLANE_URL: 'https://umedleemutmvd.dataplane.rudderstack.com',
};

const oneTrustDevKey = 'db82898c-a511-431f-87f9-faefb0ec8749-test';
const ukOneTrustProdKey = 'db82898c-a511-431f-87f9-faefb0ec8749'; // use as default
const usOneTrustProdKeys: { [key: string]: string } = {
	'us.umed.io': '', // No analytics required
	'm.accesspd.org': '018e7558-d513-79a6-8a24-7199cfaaf307',
	'm.accessild.org': '018e7560-68d1-723b-8fa9-b24582c88d34',
	'm.accesscmd.org': '018e7560-ec07-79a1-a38c-cb170f40267a',
};
const currentHostname = window.location.hostname;
const oneTrustKey = isProdSite
	? usOneTrustProdKeys[currentHostname] || ukOneTrustProdKey
	: oneTrustDevKey;

export const ONETRUST_SETUP = {
	KEY: oneTrustKey,
	SCRIPT_SRC: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
	ANALYTICS_CATEGORY_ID: 'ANLYT',
};

export const available_languages: { [key: string]: string } = {
	'en-gb': 'English (UK)',
	'en-us': 'English (US)',
	'fr-ca': 'French (Canada)',
	'es-us': 'Spanish (US)',
};
