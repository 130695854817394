import { RudderAnalytics } from '@rudderstack/analytics-js';

import { ColumnVisibility } from '@/components/Form';
import { RUDDERSTACK_SETUP, ONETRUST_SETUP } from '@/globalConsts';
import { setScreenSizeAnalyticsValues } from '@/utils';

export const pageView = (properties: { [key: string]: string | number | boolean } | null) => {
	if (window?.rudderanalytics) {
		const pagePath = window?.location?.pathname + window?.location?.search;
		// check path to ensure page view is only sent once
		if (pagePath !== window.rudderanalyticsPage) {
			// add screen size data to page view
			const screenSizeData = setScreenSizeAnalyticsValues();
			const options = {
				viewport: screenSizeData.viewport,
				screenResolution: screenSizeData.screenResolution,
				...properties,
			};
			window.rudderanalytics.page(null, options);
			window.rudderanalyticsPage = pagePath;
		}
	}
};

export const loadAnalytics = () => {
	if (
		window.OneTrust.IsAlertBoxClosed() &&
		window.OnetrustActiveGroups.indexOf(ONETRUST_SETUP.ANALYTICS_CATEGORY_ID) !== -1 &&
		!window.rudderanalytics
	) {
		// only load Rudderstack if user has consented to analytics cookies
		const rudderAnalytics = new RudderAnalytics();
		rudderAnalytics.load(RUDDERSTACK_SETUP.WRITE_KEY, RUDDERSTACK_SETUP.DATA_PLANE_URL, {
			// TODO - Fix this
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			cookieConsentManager: {
				oneTrust: {
					enabled: true,
				},
			},
		});
		window.rudderanalytics = rudderAnalytics;
		// trigger view page view as it wouldn't have been sent on load
		pageView({});
	}
};

export const trackEvent = (
	name: string,
	properties?: { [key: string]: string | number | boolean | undefined | ColumnVisibility } | null
) => {
	window?.rudderanalytics && window.rudderanalytics.track(name, properties);
};

export const trackIdentify = (
	identifier: string,
	properties: { [key: string]: string | string[] | number | boolean } | null
) => {
	window?.rudderanalytics && window.rudderanalytics.identify(identifier, properties);
};

export const loadCookieConsent = () => {
	if (ONETRUST_SETUP.KEY && !window.OptanonWrapper) {
		// adds the OneTrust script to the page body
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = ONETRUST_SETUP.SCRIPT_SRC;
		script.dataset.documentLanguage = 'true';
		script.dataset.domainScript = ONETRUST_SETUP.KEY;
		document.body.appendChild(script);

		// add OneTrust callback function
		window.OptanonWrapper = () => {
			loadAnalytics();
		};
	}
};
